import React from "react";
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faVideo } from "@fortawesome/free-solid-svg-icons";

export const hrefRegex = /href=/;

export const urlRegex = /www\./;

export const user_data = {
    name: "Whitney Francis",
    email: "whitney@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
};

export const uuidValidationRegEx =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const ContactLinks = {
    socialMediaLinks: [
        {
            name: "Facebook",
            link: "https://www.facebook.com/tacnique",
            icon: "/logos/facebook-c.svg",
        },
        {
            name: "LinkedIn",
            link: "https://www.linkedin.com/company/tacnique/",
            icon: "/logos/linkedin-c.svg",
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com/tacnique",
            icon: "/logos/instagram-c.svg",
        },
        {
            name: "YouTube",
            link: "https://www.youtube.com/@tacnique",
            icon: "/logos/youtube-c.svg",
        },
        {
            name: "Twitter",
            link: "https://twitter.com/tacnique",
            icon: "/logos/twitter-c.svg",
        },
    ],
    browseLinks: [
        {
            name: "About Us",
            link: "/about",
        },
        {
            name: "Job Openings",
            link: "/openings",
        },
        {
            name: "Blog",
            link: "https://blog.tacnique.com",
        },
    ],
    supportLinks: [
        {
            name: "Privacy Policy",
            link: "/privacy-policy",
        },
        {
            name: "Terms & Conditions",
            link: "/terms-and-conditions",
        },
        {
            name: "Cookie Policy",
            link: "/cookie-policy",
        },
    ],
};

export const footerNavigation = {
    solutions: [
        { name: "Marketing", href: "#" },
        { name: "Analytics", href: "#" },
        { name: "Commerce", href: "#" },
        { name: "Insights", href: "#" },
    ],
    support: [
        { name: "Pricing", href: "#" },
        { name: "Documentation", href: "#" },
        { name: "Guides", href: "#" },
        { name: "API Status", href: "#" },
    ],
    company: [
        { name: "About", href: "#" },
        { name: "Blog", href: "#" },
        { name: "Jobs", href: "#" },
        { name: "Press", href: "#" },
        { name: "Partners", href: "#" },
    ],
    legal: [
        { name: "Claim", href: "#" },
        { name: "Privacy", href: "#" },
        { name: "Terms", href: "#" },
    ],
    social: [
        {
            name: "Facebook",
            href: "https://www.facebook.com/tacnique",
            icon: (props) => (
                <FontAwesomeIcon
                    icon={faFacebook}
                    aria-hidden="true"
                    {...props}
                />
            ),
            active: true,
        },
        {
            name: "Instagram",
            href: "https://www.instagram.com/tacnique",
            icon: (props) => (
                <FontAwesomeIcon
                    icon={faInstagram}
                    aria-hidden="true"
                    {...props}
                />
            ),
            active: true,
        },
        {
            name: "LinkedIn",
            href: "https://www.linkedin.com/company/tacnique/",
            icon: (props) => (
                <FontAwesomeIcon
                    icon={faLinkedin}
                    aria-hidden="true"
                    {...props}
                />
            ),
            active: true,
        },
        {
            name: "Twitter",
            href: "https://twitter.com/tacnique",
            icon: (props) => (
                <FontAwesomeIcon
                    icon={faTwitter}
                    aria-hidden="true"
                    {...props}
                />
            ),
            active: true,
        },
        {
            name: "GitHub",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
            active: false,
        },
        {
            name: "YouTube",
            href: "https://www.youtube.com/@tacnique",
            icon: (props) => (
                <FontAwesomeIcon
                    icon={faYoutube}
                    aria-hidden="true"
                    {...props}
                />
            ),
            active: true,
        },
    ],
};

export const copyrightText = "2024 Tacnique. All Rights Reserved.";

export const homeNavigation = [
    {
        name: "For Companies",
        href: "/",
        links: ["/", "/companies", "/businesses"],
        children: [
            {
                name: "Verified Talent Pool",
                href: "/companies/verified-talent-pool",
                links: ["/companies/verified-talent-pool"],
                description: "Access screened and interviewed candidates.",
                icon: (props) => <FontAwesomeIcon icon={faVideo} {...props} />,
            },
            {
                name: "Outsource Tech Interview",
                href: "/companies/outsource-tech-interview",
                links: ["/companies/outsource-tech-interview"],
                description: "Conduct technical interviews of candidates.",
                icon: (props) => <FontAwesomeIcon icon={faUsers} {...props} />,
            },
        ],
    },
    {
        name: "For Candidates",
        href: "/engineers",
        links: ["/engineers"],
    },

    {
        name: "Browse Jobs",
        href: "/openings",
        links: ["/openings", "/openings/[openingId]"],
    },
    { name: "About", href: "/about", links: ["/about"] },
    {
        name: "Blog",
        href: "https://blog.tacnique.com",
        links: ["https://blog.tacnique.com"],
    },
];

export const companyNavigation = [
    { name: "Dashboard", href: "/company/dashboard" },
    { name: "Requirements", href: "/company/requirements" },
    { name: "Candidates", href: "/company/candidates" },
    {
        name: "Interviews",
        href: "/company/interviews",
        conditions: ["organization-has-interviewing-platform_access"],
        children: [
            {
                name: "Question Bank",
                href: "/company/interviews/questions",
                links: ["/company/interviews/questions"],
            },
            {
                name: "Interview Roles",
                href: "/company/interviews/roles",
                links: ["/company/interviews/roles"],
            },
            {
                name: "Interview Calendar",
                href: "/company/interviews/calendar",
                links: ["/company/interviews/calendar"],
            },
            {
                name: "Async Video Interviews",
                href: "/company/interviews/async-video",
                links: ["/company/interviews/async-video"],
            },
        ],
    },
];

export const engineerNavigation = [
    { name: "Profile", href: "/engineer/profile" },
    { name: "Interviews", href: "/engineer/interviews" },
    // Disabling openings and offers tab temporarily
    // { name: "Offers", href: "/engineer/offers" },
    // { name: "Openings", href: "/engineer/openings" },
];

export const comments = [
    {
        id: 1,
        name: "Sanjana Gupta",
        date: "4d ago",
        imageId: "1494790108377-be9c29b29330",
        body: "Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.",
    },
    {
        id: 2,
        name: "Manish Jain",
        date: "4d ago",
        imageId: "1519244703995-f4e0f30006d5",
        body: "Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.",
    },
    {
        id: 3,
        name: "Joe Vincent",
        date: "4d ago",
        imageId: "1506794778202-cad84cf45f1d",
        body: "Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.",
    },
];

export const placeholderColors = [
    "bg-red-700",
    "bg-orange-700",
    "bg-emerald-500",
    "bg-cyan-600",
    "bg-blue-500",
    "bg-fuchsia-600",
    "bg-pink-500",
];

export const graphColors = ["#36A2EB", "#FF6384"];

export const candidateStatus = {
    all: "All",
    new: "New",
    screened: "Screened",
    scheduled: "Scheduled",
    interviewed: "Interviewed",
    shortlisted: "Shortlisted",
    hired: "Hired",
    rejected: "Rejected",
};

export const tzStrings = [
    { label: "(GMT-12:00) International Date Line West", value: "Etc/GMT+12" },
    { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
    { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
    { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
    {
        label: "(GMT-08:00) Pacific Time (US & Canada)",
        value: "America/Los_Angeles",
    },
    { label: "(GMT-08:00) Tijuana, Baja California", value: "America/Tijuana" },
    { label: "(GMT-07:00) Arizona", value: "US/Arizona" },
    {
        label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
        value: "America/Chihuahua",
    },
    { label: "(GMT-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
    { label: "(GMT-06:00) Central America", value: "America/Managua" },
    { label: "(GMT-06:00) Central Time (US & Canada)", value: "US/Central" },
    {
        label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
        value: "America/Mexico_City",
    },
    { label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
    {
        label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
        value: "America/Bogota",
    },
    { label: "(GMT-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
    { label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
    { label: "(GMT-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
    { label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
    { label: "(GMT-04:00) Manaus", value: "America/Manaus" },
    { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
    { label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
    { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
    {
        label: "(GMT-03:00) Buenos Aires, Georgetown",
        value: "America/Argentina/Buenos_Aires",
    },
    { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
    { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
    { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
    { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
    { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
    {
        label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
        value: "Africa/Casablanca",
    },
    {
        label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        value: "Etc/Greenwich",
    },
    { label: "(GMT) UTC ", value: "UTC" },
    {
        label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        value: "Europe/Amsterdam",
    },
    {
        label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        value: "Europe/Belgrade",
    },
    {
        label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
        value: "Europe/Brussels",
    },
    {
        label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        value: "Europe/Sarajevo",
    },
    { label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
    { label: "(GMT+02:00) Amman", value: "Asia/Amman" },
    {
        label: "(GMT+02:00) Athens, Bucharest, Istanbul",
        value: "Europe/Athens",
    },
    { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
    { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
    { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
    {
        label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        value: "Europe/Helsinki",
    },
    { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
    { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
    { label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
    { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
    {
        label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
        value: "Europe/Moscow",
    },
    { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
    { label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
    { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
    { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
    { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
    { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
    { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
    { label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
    {
        label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
        value: "Asia/Karachi",
    },
    {
        label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        value: "Asia/Kolkata",
    },
    { label: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Calcutta" },
    { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
    { label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
    { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
    { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
    { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
    { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
    {
        label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        value: "Asia/Hong_Kong",
    },
    {
        label: "(GMT+08:00) Kuala Lumpur, Singapore",
        value: "Asia/Kuala_Lumpur",
    },
    { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
    { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
    { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
    { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
    { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
    { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
    { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
    { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
    { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
    {
        label: "(GMT+10:00) Canberra, Melbourne, Sydney",
        value: "Australia/Canberra",
    },
    { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
    { label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
    { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
    {
        label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
        value: "Asia/Magadan",
    },
    { label: "(GMT+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
    {
        label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
        value: "Pacific/Fiji",
    },
    { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];

export const mappedTimeZones = {
    "Asia/Calcutta": "Asia/Kolkata",
};

export const companyRoles = [
    "org-admin",
    "interview-scheduler",
    "interviewer",
    "client-admin",
    "client",
    "ta-manager",
    "sales-manager",
];

export const engineerRoles = ["interviewee"];

export const seniorityOptions = [
    { value: "ASSOCIATE", label: "Associate" },
    { value: "JUNIOR", label: "Junior" },
    { value: "SENIOR", label: "Senior" },
    { value: "EXPERT", label: "Expert" },
    { value: "PRINCIPAL", label: "Principal" },
    { value: "DISTINGUISHED", label: "Distinguished" },
];

export const workPlaceOptions = [
    { value: "ONSITE", label: "Onsite" },
    { value: "REMOTE", label: "Remote" },
    { value: "HYBRID", label: "Hybrid" },
];

export const analyticsEvents = {
    USER_LOGGED_OUT: "User Logged Out",
    USER_LOGGED_IN: "User Logged In",
    USER_SIGNUP: "User Signed Up",
    PROFILE_UPDATED: "Profile Updated",
    ACCOUNT_UPDATED: "Account Updated",
    DASHBOARD_CHART_CLICKED: "Dashboard Chart Clicked",
    CANDIDATE_ADDED: "Candidate Added",
    CANDIDATE_SHORTLISTED: "Candidate Shortlisted",
    CANDIDATE_HIRED: "Candidate Hired",
    CANDIDATE_REJECTED: "Candidate Rejected",
    REQUIREMENT_CREATED: "Requirement Created",
    REQUIREMENT_CLONED: "Requirement Cloned",
    REQUIREMENT_UPDATED: "Requirement Updated",
    INTERVIEW_RECORDING_PLAYED: "Interview Recording Played",
    CANDIDATE_UPDATED: "Candidate Updated",
    INTERVIEW_CREATED: "Interview Created",
    INTERVIEW_UPDATED: "Interview Updated",
    OPENING_VIEWED: "Opening Viewed",
    OPENING_APPLIED: "Opening Applied",
    USER_SHARE_CREATED: "User Share Created",
    USER_SHARE_ACCEPTED: "User Share Accepted",
    CANDIDATE_UNLOCKED: "Candidate Unlocked",
    ONBOARDING_STEP_COMPLETED: "Onboarding Step Completed",
    ONBOARDING_COMPLETED: "Onboarding Completed",
    ENGINEER_PROFILE_UPDATED: "Engineer Profile Updated",
};

export const noticePeriodStatuses = [
    {
        value: "Yes, I am serving my notice",
        label: "Yes, I am serving my notice",
    },
    {
        value: "No, I have not resigned yet",
        label: "No, I have not resigned yet",
    },
    {
        value: "I am not employed currently",
        label: "I am not employed currently",
    },
];

export const noticePeriodStatusesFilter = [
    {
        value: "Yes, I am serving my notice",
        label: "Serving notice period",
    },
    {
        value: "No, I have not resigned yet",
        label: "Not resigned yet",
    },
    {
        value: "I am not employed currently",
        label: "Not employed currently",
    },
];

export const candidateApplicationMode = [
    { value: "All", label: "All" },
    {
        value: "Referred",
        label: "Added by you",
    },
    {
        value: "Registered",
        label: "Applied by Candidate",
    },
    {
        value: "Sourced",
        label: "Recommended by Tacnique",
    },
    {
        value: "Shared",
        label: "Shared",
    },
];

export const candidateApplicationModes = [
    "Referred",
    "Registered",
    "Sourced",
    "Shared",
];

export const candidateNavigation = [
    "new",
    "screened",
    "scheduled",
    "interviewed",
    "rejected",
    "shortlisted",
    "hired",
];

export const englishProficiencyLevel = [
    {
        value: "Basic",
        label: "Basic",
        description:
            "I can only communicate in written form over email or Slack effectively. Verbal communication in English with a engineering manager would be slow and impractical.",
    },
    {
        value: "Average",
        label: "Average",
        description:
            "I can communicate with a engineering manager if we both speak slowly and occasionally repeat sentences that the other person didn't understand.",
    },
    {
        value: "Great",
        label: "Great",
        description:
            "Both engineering managers and I can easily understand each other without having to repeat much.",
    },
];

export const interviewerChoiceOptions = [
    {
        value: "in-house",
        label: "In House Interviewers",
    },
    {
        value: "tacnique",
        label: "Tacnique Experts",
    },
];

export const availabilityStatus = [
    {
        value: "Actively Looking",
        label: "Actively Looking",
        description:
            "I am actively looking for a new opportunity. I want to have my master interview ready ASAP so I can apply endlessly.",
    },
    {
        value: "Passively Looking",
        label: "Passively Looking",
        description:
            "I am not actively looking for a change, but I want to give a master interview so I can keep an eye for new exciting opportunities.",
    },
    {
        value: "Unavailable",
        label: "Unavailable",
        description:
            "I am happy and getting the growth I desire where I am. So, I am not looking for a new opportunity at the moment.",
    },
];

export const preferredPronoun = [
    {
        value: "he/him",
        label: "He/Him",
        description: "he/him",
    },
    {
        value: "she/her",
        label: "She/Her",
        description: "she/her",
    },
    {
        value: "they/them",
        label: "They/Them",
        description: "they/them",
    },
];

export const educationBackground = [
    {
        value: "Bachelors (or equivalent)",
        label: "Bachelors (or equivalent)",
    },
    {
        value: "MBA (or equivalent)",
        label: "MBA (or equivalent)",
    },
    {
        value: "Masters (or equivalent)",
        label: "Masters (or equivalent)",
    },
    {
        value: "Secondary/High School (or equivalent)",
        label: "Secondary/High School (or equivalent)",
    },
    {
        value: "Other degree",
        label: "Other degree",
    },
];

export const roleOptions = [
    {
        value: "client-admin",
        label: "Admin",
    },
    {
        value: "client",
        label: "Reviewer",
    },
    {
        value: "interviewer",
        label: "Interviewer",
    },
];

// Not being used right now
export const whereDidYourHearAboutUs = [
    {
        value: "Received email from Tacnique",
        label: "Received email from Tacnique",
    },
    {
        value: "Found Tacnique when searching for jobs on Google",
        label: "Found Tacnique when searching for jobs on Google",
    },
    {
        value: "Facebook ads",
        label: "Facebook ads",
    },
    {
        value: "Youtube ads",
        label: "Youtube ads",
    },
    {
        value: "Linkedin / Quora / Twitter / Reddit ads",
        label: "Linkedin / Quora / Twitter / Reddit ads",
    },
    {
        value: "Billboards / TV / Newspaper ads",
        label: "Billboards / TV / Newspaper ads",
    },
    {
        value: "Podcast / Music app",
        label: "Podcast / Music app",
    },
    {
        value: "Online news / 3rd party websites",
        label: "Online news / 3rd party websites",
    },
    {
        value: "Job posting sites",
        label: "Job posting sites",
    },
    {
        value: "Referral from a friend /colleague / word of mouth",
        label: "Referral from a friend /colleague / word of mouth",
    },
    {
        value: "Social media post",
        label: "Social media post",
    },
    {
        value: "Tacnique Community event",
        label: "Tacnique Community event",
    },
    {
        value: "Other Events / Conferences",
        label: "Other Events / Conferences",
    },
    {
        value: "Other",
        label: "Other",
    },
];

export const sliderCloudEngineers = [
    [
        {
            icon: "/home/star.svg",
            title: "Top Opportunities",
            desc: "Discover opportunities with the parameters that fit perfectly with your aspiration.",
        },
        {
            icon: "/home/globe.svg",
            title: "Work Anywhere",
            desc: "Apply at your matching organizations worldwide and find the best opportunity for your growth.",
        },
        {
            icon: "/home/detail.svg",
            title: "Job Recommendations",
            desc: "Make your job applications well-informed increasing chances of success.",
        },
    ],
    [
        {
            icon: "/home/quick.svg",
            title: "Quick Turn-around",
            desc: "Avoid coordination delays due to interviewer unavailability or bandwidth shortage.",
        },
        {
            icon: "/home/focus.svg",
            title: "Engineer Focused",
            desc: "Quantify your technical skills and get benchmarked using Tacnique score.",
        },
        {
            icon: "/home/streamlined-process.svg",
            title: "Streamlined Process",
            desc: "Rely on process honed over a decade with a well-defined and role-specific interview process.",
        },
    ],
];

export const timezones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kanton",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
    "UTC",
];

export const defaultCurrency = "INR";

export const candidateUnlockThreshold = {
    max: 2,
    min: 0,
};

export const whyItemsResponsive = [
    {
        imgSrc: "/home/expert.svg",
        testId: "certified-expert-interviewers",
        title: "Certified Expert Interviewers",
    },
    {
        imgSrc: "/home/flexible-c.svg",
        testId: "score-based-summary",
        title: "Score-based Summary",
    },
    {
        imgSrc: "/home/insights.svg",
        testId: "detailed-interview-insights",
        title: "Detailed Interview Insights",
    },
    {
        imgSrc: "/home/structure.svg",
        testId: "ease-of-access",
        title: "Ease of Access",
    },
];

export const companyWhyItems = [
    {
        containerClass: "deg45 why",
        innerContainerClass:
            "p-6 pl-[10px] pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-56 lg:w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/structure.svg",
        testId: "ease-of-access",
        title: "Ease of Access",
    },
    {
        containerClass: "deg190 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-56 lg:w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/culture.svg",
        testId: "ai-video-intelligence",
        title: "AI & Video Intelligence",
    },
    {
        containerClass: "deg345 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-56 lg:w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/insights.svg",
        testId: "detailed-interview-insights",
        title: "Detailed Interview Insights",
    },
    {
        containerClass: "deg225 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/video.svg",
        testId: "cloud-interview-recordings",
        title: "Cloud Interview Recordings",
    },
    {
        containerClass: "deg315 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/expert.svg",
        testId: "certified-expert-interviewers",
        title: "Certified Expert Interviewers",
    },
    {
        containerClass: "deg130 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/behaviour.svg",
        testId: "behavioral-assessment",
        title: "Behavioral Assessment",
    },
    {
        containerClass: "deg160 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/stars.svg",
        testId: "technical-skill-assessment",
        title: "Technical Skill Assessment",
    },
    {
        containerClass: "deg15 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/flexible-c.svg",
        testId: "score-based-summary",
        title: "Score-based Summary",
    },
];

export const companyItemsForResponsiveView = [
    {
        imgSrc: "/home/video.svg",
        testId: "cloud-based-recordings",
        title: "Cloud Interview Recordings",
    },
    {
        imgSrc: "/home/stars.svg",
        testId: "technical-skill-assessment",
        title: "Technical Skill Assessment",
    },
    {
        imgSrc: "/home/behaviour.svg",
        testId: "behavioral-assessment",
        title: "Behavioral Assessment",
    },
    {
        imgSrc: "/home/culture.svg",
        testId: "ai-video-intelligence",
        title: "AI & Video Intelligence",
    },
];

export const engineeringWhyItems = [
    {
        containerClass: "deg45 why",
        innerContainerClass:
            "p-6 pl-[10px] pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/structure.svg",
        testId: "ease-of-access",
        title: "Ease of Access",
    },
    {
        containerClass: "deg190 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/culture.svg",
        testId: "ai-recommendation-engine",
        title: "AI Recommendation Engine",
    },
    {
        containerClass: "deg345 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/insights.svg",
        testId: "detailed-interview-insights",
        title: "Detailed Interview Insights",
    },
    {
        containerClass: "deg225 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/video.svg",
        testId: "cloud-interview-recordings",
        title: "Cloud Interview Recordings",
    },
    {
        containerClass: "deg315 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/expert.svg",
        testId: "certified-expert-interviewers",
        title: "Certified Expert Interviewers",
    },
    {
        containerClass: "deg130 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/behaviour.svg",
        testId: "behavioral-assessment",
        title: "Behavioral Assessment",
    },
    {
        containerClass: "deg160 left-position why",
        innerContainerClass:
            "p-6 pr-[10px] rounded-full flex items-top justify-end space-x-6 items-center w-72 h-12 shadow-[10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: false,
        imgSrc: "/home/stars.svg",
        testId: "technical-skill-assessment",
        title: "Technical Skill Assessment",
    },
    {
        containerClass: "deg15 why",
        innerContainerClass:
            "p-6 pl-[10px] rounded-full flex items-top justify-start space-x-6 items-center w-72 h-12 shadow-[-10px_0px_10px_0px_#6a6a6a40]",
        imageLeft: true,
        imgSrc: "/home/flexible-c.svg",
        testId: "score-based-summary",
        title: "Score-based Summary",
    },
];

export const engineerItemsForResponsiveView = [
    {
        imgSrc: "/home/video.svg",
        testId: "cloud-based-recordings",
        title: "Cloud Interview Recordings",
    },
    {
        imgSrc: "/home/stars.svg",
        testId: "technical-skill-assessment",
        title: "Technical Skill Assessment",
    },
    {
        imgSrc: "/home/behaviour.svg",
        testId: "behavioral-assessment",
        title: "Behavioral Assessment",
    },
    {
        imgSrc: "/home/culture.svg",
        testId: "ai-recommendation-engine",
        title: "AI Recommendation Engine",
    },
];

export const challengesList = [
    {
        icon: "/home/time-c.svg",
        title: "49 days invested per hire",
        desc: "People in various roles in the organization spend months before they reach a successful hire.",
        source: "/home/logos_linkedin.svg",
        link: "#",
    },
    {
        icon: "/home/c-dollar.svg",
        title: "$4000 spent on every hire",
        desc: "The significant amount of bandwidth invested per hire turns into a heavy cost center for a growing business.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.businessnewsdaily.com/16562-cost-of-hiring-an-employee.html",
    },
    {
        icon: "/home/c-resume.svg",
        title: "250 applications screened for each hire",
        desc: "The volume of applications coming from various sources frequently overwhelms the hiring teams.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.glassdoor.com/employers/blog/50-hr-recruiting-stats-make-think/",
    },
    {
        icon: "/home/c-hour.svg",
        title: "23.7 days taken per interview process",
        desc: "Finding available time between candidates and in-house interviewing engineers results in further delays.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.bbc.com/worklife/article/20211020-why-hiring-takes-so-long",
    },
];

export const cloudHiring = [
    {
        icon: "/home/star.svg",
        title: "Top Talent",
        desc: "Discover engineers with the skills and experience that fit perfectly within your culture.",
    },
    {
        icon: "/home/globe.svg",
        title: "Global Reach",
        desc: "Get a competitive edge by tapping into tech talent pool across the globe.",
    },
    {
        icon: "/home/detail.svg",
        title: "Detailed Insights",
        desc: "Make your hiring decisions well-informed and free from bias.",
    },
    {
        icon: "/home/quick.svg",
        title: "Quick Turn-around",
        desc: "Avoid co-ordination delays due to candidate unavailability or bandwidth shortage.",
    },
    {
        icon: "/home/focus.svg",
        title: "Engineer Focused",
        desc: "Opt for a hiring platform designed for giving best interview experience to engineers.",
    },
    {
        icon: "/home/streamlined-process.svg",
        title: "Streamlined Process",
        desc: "Rely on process honed over a decade with well-defined and role-specific interview process.",
    },
];

export const challengesEngineers = [
    {
        icon: "/home/time-c.svg",
        title: "10-15 job applications a week for every job hunt",
        desc: "Job hunting for engineers becomes a volume game and requires significant time to find the right opportunity.",
        source: "/home/logos_linkedin.svg",
        link: "https://www.cnbc.com/2021/08/06/these-industries-have-the-slowest-hiring-times-according-to-linkedin-.html0",
    },
    {
        icon: "/home/c-dollar.svg",
        title: "8-10 hours spent a week on job applications",
        desc: "With a focus on perfecting each application, engineers end up investing countless hours.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.businessnewsdaily.com/16562-cost-of-hiring-an-employee.html",
    },
    {
        icon: "/home/c-resume.svg",
        title: "5-10 hours spent in preparation per interview",
        desc: "No standard interview process means repetitive preparation and different expectation to perform.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.glassdoor.com/employers/blog/50-hr-recruiting-stats-make-think/",
    },
    {
        icon: "/home/c-hour.svg",
        title: "9% probability of interview turning into offer",
        desc: "You do not know enough to evaluate the opportunity reducing chances of success drastically.",
        source: "/home/logos_glassdoor.svg",
        link: "https://www.bbc.com/worklife/article/20211020-why-hiring-takes-so-long",
    },
];

export const cloudEngineers = [
    {
        icon: "/home/star.svg",
        title: "Top Opportunities",
        desc: "Discover opportunities with the parameters that fit perfectly with your aspiration.",
    },
    {
        icon: "/home/globe.svg",
        title: "Work Anywhere",
        desc: "Apply at your matching organizations worldwide and find the best opportunity for your growth.",
    },
    {
        icon: "/home/detail.svg",
        title: "Job Recommendations",
        desc: "Make your job applications well-informed increasing chances of success.",
    },
    {
        icon: "/home/quick.svg",
        title: "Quick Turn-around",
        desc: "Avoid coordination delays due to interviewer unavailability or bandwidth shortage.",
    },
    {
        icon: "/home/focus.svg",
        title: "Engineer Focused",
        desc: "Quantify your technical skills and get benchmarked using Tacnique score.",
    },
    {
        icon: "/home/streamlined-process.svg",
        title: "Streamlined Process",
        desc: "Rely on process honed over a decade with a well-defined and role-specific interview process.",
    },
];

export const playbackRates = [0.5, 1, 1.5, 2];

export const guestRoutes = [
    "/",
    "/404",
    "/about",
    "/demo",
    "/demo/confirmation",
    "/companies",
    "/engineers",
    "/businesses",
    "/share/[shareCode]",
    "/openings",
    "/openings/[openingId]",
    "/companies/outsource-tech-interview",
    "/companies/verified-talent-pool",
    "/privacy-policy",
    "/cookie-policy",
    "/terms-and-conditions",
    "/interviews/[interviewUuid]/questions/[questionUuid]",
];

export const redactedPlaceholder = "****";

export const supportedResumeFileMimes = [
    "application/doc",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const supportedVideoFileMimes = ["video/mp4", "video/webm"];

export const noticePeriodStrings = {
    "Yes, I am serving my notice": "Serving Notice",
    "No, I have not resigned yet": "Yet To Resign",
    "I am not employed currently": "Not Employed",
};

export const awsPublicS3Config = {
    accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
};

export const infoBannerText =
    "We are currently inviting engineers with limited number of skills to schedule an interview. Please check back later if you do not see the desired skill.";

export const internalRoutes = [
    "/company/",
    "/engineer/",
    "/interviews",
    "/404",
    "/500",
    "/force-add-details",
    "/force-reset-password",
    "/forgot-password",
    "/login",
    "/signup",
    "/verify-email",
    "/openings",
];

export const interviewDurationOptions = [
    {
        value: 30,
        label: 30,
    },
    {
        value: 45,
        label: 45,
    },
    {
        value: 60,
        label: 60,
    },
    {
        value: 75,
        label: 75,
    },
    {
        value: 90,
        label: 90,
    },
];

export const questionDifficulties = ["EASY", "MEDIUM", "HARD", "VERY HARD"];

export const questionStatus = ["Answered", "Pinned"];

export const keysToExtract = [
    "page",
    "limit",
    "requirement_id",
    "skill_ids",
    "country_id",
    "city_id",
    "notice_period_start",
    "notice_period_end",
    "salary_start",
    "salary_end",
    "screenedBefore",
    "screenedAfter",
    "interviewedBefore",
    "interviewedAfter",
    "application_mode",
    "notice_period_status",
    "work_place_preference",
];

export const questionTypes = [
    "Coding",
    "Querying",
    "Knowledge",
    "Behavioral",
    "Scenario",
];

export const chartLegendMargin = {
    id: "legendMargin",
    beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            return (this.height += 30);
        };
    },
};

export const defaultMarkdownTools = [
    "bold",
    "italic",
    "heading",
    "|",
    "quote",
    "unordered-list",
    "ordered-list",
    "|",
    "image",
    "table",
    "|",
    "code",
    "horizontal-rule",
    "|",
    "undo",
    "redo",
    "|",
    "strikethrough",
    "|",
    "fullscreen",
    "side-by-side",
    "preview",
];
